import dynamic from 'next/dynamic';

export default {
    BasePage: dynamic(() => import('./BasePage')),
    CareerPage: dynamic(() => import('./CareerPage')),
    CaseListPage: dynamic(() => import('./CaseListPage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    OffersPage: dynamic(() => import('./OffersPage')),
    PageArchive: dynamic(() => import('./PageArchive')),
    PageArticle: dynamic(() => import('./PageArticle')),
    PageCase: dynamic(() => import('./PageCase')),
    PageCaseEnhanced: dynamic(() => import('./PageCaseEnhanced')),
    PageNews: dynamic(() => import('./PageNews')),
    PageNotFound: dynamic(() => import('./PageNotFound')),
    PageStandard: dynamic(() => import('./PageStandard')),
    StartPage: dynamic(() => import('./StartPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
};
